<template>
  <span class="field">
    <ClientOnly v-if="['tel', 'email'].includes(type)">
      <IMaskComponent
        v-model="inputValue"
        class="field__input"
        :placeholder="placeholder"
        v-bind="MASK_TYPE[type]"
        @accept:unmasked="onComplete"
      />
    </ClientOnly>

    <ClientOnly v-else-if="['date', 'time', 'dateTime'].includes(type)">
      <VueDatePicker
        v-model="inputValue"
        locale="ru"
        auto-apply
        :format="modelFormatForCalendar[type]"
        :model-type="modelFormatForCalendar[type]"
        :time-picker="type === 'time'"
        :enable-time-picker="type === 'dateTime'"
        @update:model-value="onSetDate"
      >
        <template #dp-input="{ value }">
          <input class="field__input" type="text" :placeholder="placeholder" :value="value" />
        </template>
      </VueDatePicker>
    </ClientOnly>

    <textarea
      v-else-if="type === 'textarea'"
      v-model="inputValue"
      class="field__input"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', inputValue)"
    ></textarea>

    <input
      v-else
      v-model="inputValue"
      class="field__input"
      autocomplete="new-password"
      :autofocus="autofocus"
      :type="currentType"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', inputValue)"
    />

    <NuxtIcon
      v-if="type === 'password'"
      class="field__password-icon"
      :name="isShowPassword ? 'IconEyeOpened' : 'IconEyeClosed'"
      @click="showPassword"
    />
  </span>
</template>

<script setup lang="ts">
import { IMaskComponent } from 'vue-imask';
import VueDatePicker from '@vuepic/vue-datepicker';
import { ref, computed } from '#imports';
import './styles.scss';
import '@vuepic/vue-datepicker/dist/main.css';

interface Props {
  modelValue: string | number;
  type:
    | 'text'
    | 'tel'
    | 'email'
    | 'password'
    | 'search'
    | 'url'
    | 'date'
    | 'time'
    | 'dateTime'
    | 'textarea';
  placeholder: string;
  autofocus?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);
const inputValue = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value),
});
const currentType = ref(props.type);
const isShowPassword = ref(false);

const MASK_TYPE = {
  tel: {
    mask: '+{7}0000000000',
    // unmask: true,
    // lazy: false,
    // eager: false,
    // overwrite: true,
  },
  // 'email': {
  //     mask: 'w@w.w',
  //     blocks: {
  //         w: {
  //             mask: /\w*/g,
  //         },
  //     },
  //     lazy: false,
  //     eager: false,
  //     overwrite: true
  // },
};

const showPassword = () => {
  isShowPassword.value = !isShowPassword.value;
  if (isShowPassword.value) {
    currentType.value = 'text';
  } else {
    currentType.value = 'password';
  }
};

// todo подумать над правильностью метода
const onComplete = () => {
  emit('update:modelValue', inputValue.value);
};

const modelFormatForCalendar = {
  date: 'dd.MM.yyyy',
  time: 'HH:mm',
  dateTime: 'dd.MM.yyyy HH:mm',
};

const onSetDate = (value: string) => {
  inputValue.value = value;
  emit('update:modelValue', value);
};
</script>
